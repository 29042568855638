import React from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';

import TextBoxWithHeading from '../components/text/textboxWithHeading';
import Title from '../components/text/title';

import { Line } from '../components/utility/line';
import ContentContainer from '../components/global/contentContainer';

const Theme = {
  highlightColor: '#00aa90',
  textColor: '#000000',
};

const ContainerTheme = {
  backgroundColor: '#d2f5e6',
  paddingBottom: 54,
};

const Jobs = ({ data, location }) => {
  const jobListings = data.prismicJobs.data.job_listings.map(
    (listing, index) => (
      <React.Fragment key={index}>
        <Col col={12}>
          <Line
            margin={'54px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <ContentContainer padding={'0 36px'}>
          <TextBoxWithHeading
            linkColor={Theme.highlightColor}
            heading={listing.job_title.text}
            text={listing.job_text.html}
            padding={'0 0 0 0'}
          />
        </ContentContainer>
      </React.Fragment>
    )
  );

  return (
    <>
      <Helmet>
        <title>{data.prismicJobs.data.title.text}</title>
        <meta name="title" content={data.prismicJobs.data.title.text} />
        <meta name="description" content={data.prismicJobs.data.title.text} />
        <meta
          property="og:url"
          content={'https://www.draw-brighton.co.uk' + location.pathname}
        />
        <meta
          property="og:description"
          content={data.prismicJobs.data.title.text}
        />
        <meta property="og:locale" content="en" />
        <meta name="twitter:title" content={data.prismicJobs.data.title.text} />
        <meta
          name="twitter:description"
          content={data.prismicJobs.data.title.text}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Row css={ContainerTheme} justifyContent="center">
        <Col col={12} md={10} lg={9}>
          <ContentContainer padding={'0 36px'}>
            <Title
              margin={'108px 0 54px'}
              text={data.prismicJobs.data.title.text}
              case="inherit"
            />
          </ContentContainer>
        </Col>

        {jobListings}
      </Row>
    </>
  );
};

export const query = graphql`
  query JobsQuery {
    prismicJobs {
      data {
        title {
          text
        }
        job_listings {
          job_title {
            text
          }
          job_text {
            html
          }
        }
      }
    }
  }
`;

export default Jobs;
